import * as React from "react";
import {Layout} from "../components/layout/layout";
import SEO from "../components/SEO/SEO";
import {Banner} from "../components/banner/banner";
import homeBanner from "../images/home-banner.png";
import homeBannerLarge from "../images/home-banner-lg.png";
import homeBannerLargeWP from "../images/home-banner-lg.webp";

const pageData = {
  title: "Page not found",
  id: "not-found",
  subtitle: "Sorry, we couldn't find what you were looking for.",
  url: "/",
  bannerImage: homeBanner,
  largeBanner: homeBannerLarge,
  largeBannerWP: homeBannerLargeWP,
  hideArrows: true,
  btnText: "Go Home"
};

const NotFoundPage = () => {
  return (
    <main>
      <Layout hasBanner={false}>
        <SEO title="Page not found"/>
        <Banner pageData={pageData}/>
      </Layout>
    </main>
  )
}

export default NotFoundPage
